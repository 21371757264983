<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-lg-none">
      <li class="nav-item">
        <b-link :to="{ name: 'landing'}">
          <img class="img_logo_mobile" :src="logo" alt="logo">
        </b-link>
      </li>
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <!-- Bookmarks Container -->
      <bookmarks />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <dark-Toggler class="d-none d-lg-block" />
      <search-bar />
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  data() {
    return {
      logo: require('@/assets/images/quotation/sft_logo.svg'),
    }
  },
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
<style scoped>
  .img_logo_mobile {
    display: block;
    max-width: 200px;
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    .img_logo_mobile {
      max-width: 150px;
    }
  }
  @media screen and (max-width:460px) {
    .img_logo_mobile {
      max-width: 130px;
    }
    .navbar-floating .navbar-container {
        padding: 0.8rem 0.5rem;
    }
  }
  @media screen and (max-width:420px) {
    .img_logo_mobile {
      display:none;
    }
  }
</style>
